import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { get } from "../../utility/fetch";
import Pagination from "rc-pagination";


function PatientsTable({ data, setPatientData, totalPaginationCount, setTotalPaginationCount }) {

  const [pageNumber, setPageNumber] = useState(1);
 


  console.log(data)

  let navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  const continueUpdate = (row) => {
    sessionStorage?.setItem("patientId", row);
    // sessionStorage?.setItem("status", "edit");
    navigate(`/treatment?id=${row?.id}&patientId=${row?.patientId}`)
  }


  const searchPatient = async (name) => {
    setLoading(true)
    try {
      let res = await get(`/patient/list/${name}/1/10/search-by-name`);
    setTotalPaginationCount(res?.totalPages);
    setPatientData(res?.resultList);
    setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }


  // const handleChangePagination = (page) => {
  //   setPageNumber(page)
  //   getInventory(page);
  // }

  

  return (
    <div className="w-100">
      <div className="w-100 none-flex-item">
        <div className="flex flex-h-end m-t-20 m-b-20">
          <input onChange={(e) => searchPatient(e.target.value)} type="text" placeholder="Search" className="search-input" />
        </div>
        <div style={{ backgroundColor: "#EBFFE6", color: "#17621A" }} className="p-10"><h3>Manage Request</h3></div>
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr style={{ backgroundColor: "#CECECE", color: "#444444" }} className="border-top-none">
              {/* <th>Patient #ID</th> */}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Last Updated By</th>
              <th>Date Created</th>
            </tr>
          </thead>

          {
            (!data || loading) ? <div style={{ paddingLeft: "500px", paddingTop: "200px" }} className='flex flex-h-center w-100 flex-v-center'><GridLoader /></div> : 
            <tbody className="white-bg view-det-pane ">
              {
              data?.map((row) => (
                <tr className="hovers pointer" onClick={() => continueUpdate(row)} key={row.id}>
                  <td>{row?.firstName}</td>
                  <td>{row?.lastName}</td>
                  <td>{row?.email}</td>
                  <td>{`${row?.modifiedBy?.firstName} ${row?.modifiedBy?.lastName}`}</td>
                  <td>{new Date(row?.createdAt)?.toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          }
        </table>
        <div className="flex flex-h-end m-t-20">
          {/* <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount} /> */}
        </div>
      </div>
    </div>
  );
}

export default PatientsTable;

import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { stats } from "./mockdata/PatientData";
import StatCard from "../UI/StatCard";
import { RiCalendar2Fill } from "react-icons/ri";
import FinanceTable from "../tables/financeTable";
import { get } from "../../utility/fetchFinance";
import Pagination from "rc-pagination";

function PatientsFinance() {

  const [patientData, setPatientData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPaginationCount, setTotalPaginationCount] = useState(0);


  const getFinances = async (page=1) => {
    let res = await get(`/api/patientpayment/list/${page}/10/patient-payment-list`)
    console.log(res)
    setPatientData(res?.resultList);
    setTotalPaginationCount(res.totalCount);
  }


  const handleChangePagination = (page) => {
    setPageNumber(page)
    getFinances(page);
  }


  useEffect(() => {
    getFinances();
  }, []);
 

  return (
    <div className="w-100 m-t-80">
      <h2>Pending treatments</h2>
      
      <div className="flex w-100 space-between">
        
        <div className="flex flex-v-center w-50">
         
          {/* <input type="text" className="" /> */}
          {/* <div className="dropdown-input w-25 ">
          </div> */}
        </div>
      </div>

      <div className="">
        <FinanceTable data={patientData} />
        <div className="flex flex-h-end">
          <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount} />
        </div>
      </div>
    </div>
  );
}

export default PatientsFinance;

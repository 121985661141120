import { useEffect, useState } from "react";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import notification from "../../utility/notification";
import { get as getPatient } from "../../utility/fetchNurse";
import Notify from "../../utility/notify";
import drug from '../../assets/images/drug.png'
import genericDrug from '../../assets/images/drugGeneric.png'
import barcode from '../../assets/images/barcode.png'
import { dateHelp } from "../../utility/general";
import Accordion from "./Accordion";

const MedicationModal = ({ closeModal, setRoomData, getAmbulanceUsageData, data, getRooms, medicationName }) => {
    const [payload, setPayload] = useState({
      
    });



    const [userNames, setUserNames] = useState([]);
    const [driverNames, setDriverNames] = useState([]);
    const [ambulance, setambulance] = useState([]);
    const [showDefaultBarCode, setShowDefaultBarCode] = useState(false);
    const [showDefaultDrug, setShowDefaultDrug] = useState(false);


    const accordionItems = [
        { title: 'Ingredients', content: data?.ingredient},
        { title: 'Caution', content: data?.cautions},
        { title: 'How to Use it', content: data?.howToUseIt },
      ];

    const handleChange = (event) => {
      
    }

    const handlePicError = () => {
        setShowDefaultDrug(true);
    }

    const handleBarCodeError =()=>{
        setShowDefaultBarCode(true);
    }

    return (
        <div className="overlay m-t-20">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ display:"flex", width: '90%', margin: '0 auto', justifyContent:"center" }} >
                <div className=" no-padding bg-white white-bg p-20 w-60">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10 center-text">{data?.productName || "TYLENOL® Daytime Relief With Caffeine For Headache & Migraine Pain Share"}</h3>
                        <div className="flex m-b-10">
                            {showDefaultDrug ? <img style={{ width: "100px", height: "100px" }} src={genericDrug} alt="drug" srcset="" /> : <img style={{ width: "100px", height: "100px" }} onError={handlePicError} src={data?.productPicture || genericDrug} alt="drug" srcset="" />}
                            <p>
                               {data?.description}
                            </p>
                        </div>

                        <div className="flex w-100">
                            <TagInputs noEdit={true} type="" value={data && data?.inventoryNumber} options={userNames} name="patientId" onChange={handleChange} label="Inventory #ID" />
                            <TagInputs noEdit={true}  type="" value={data && data?.mfgBatchNumber} options={userNames} name="patientId" onChange={handleChange} label="Mfg. Batch #ID" />
                        </div>

                        <div className="flex">
                            <TagInputs noEdit={true} type="" value={data && data?.bestBefore} options={userNames} name="patientId" onChange={handleChange} label="Best Before:" />
                            <TagInputs noEdit={true} type="" value={data && data?.patientId} options={userNames} name="patientId" onChange={handleChange} label="Unit Price (N)" />
                        </div>

                        <TagInputs noEdit={true} type="textArea" value={data && data?.dosage} options={userNames} name="patientId" onChange={handleChange} label="Dosage" />

                        <div className="flex">
                            <TagInputs noEdit={true} type="" value={data && data?.dateSupplied} options={userNames} name="patientId" onChange={handleChange} label="Date Supplied" />
                            <TagInputs noEdit={true} type="" value={data && data?.supplier} options={userNames} name="patientId" onChange={handleChange} label="Supplier" />
                        </div>
                        <div>
                            <Accordion items={accordionItems}/>
                        </div>
                        <div className="m-t-20">
                           <h3>
                            Product Bar Code
                           </h3>
                           {showDefaultBarCode ? <img src={barcode} alt="barcode" /> : <img src={data?.productBarcode} onError={handleBarCodeError} alt="barcode" />}
                        </div>

                        
                    </div>
                </div>
            </div>

        </div >
    )
}

export default MedicationModal;
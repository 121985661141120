import React, { useState } from 'react';
import plus from '../../assets/images/cross.png'
import minus from '../../assets/images/minus.png'

const AccordionItem = ({ title, content, isOpen, onToggle }) => (
  <div>
    <div onClick={onToggle} style={{ cursor: 'pointer', padding: '10px', color:"#17621A", border: '1px solid #17621A', margin: '5px 0', backgroundColor: '#EBFFE6' }}>
        <div className="flex space-between flex-v-center">
            <div> {title}</div>
           {isOpen ?  <img style={{height:"3px"}} src={minus} alt="" srcset="" /> : <img style={{height:"auto"}}  src={plus} alt="" srcset="" /> }
        </div>
     
    </div>
    {isOpen && (
      <div style={{ padding: '10px', border: '1px solid #ccc', borderTop: 'none', backgroundColor: '#fff' }}>
        {content}
      </div>
    )}
  </div>
);

const Accordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
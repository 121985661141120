import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PatientData, stats } from "./mockdata/PatientData";
import StatCard from "../UI/StatCard";
import { RiCalendar2Fill } from "react-icons/ri";
import FinanceTable from "../tables/financeTable";
import DiagnosisTable from "../tables/DiagnosisTable";
import PatientsTable from "../tables/PatientsTable";
import { get } from "../../utility/fetch";
import Pagination from "rc-pagination";

function ManageRequest() {

  const [patientData, setPatientData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPaginationCount, setTotalPaginationCount] = useState(0);

  const getPatients = async(page=1) =>{
    try {
      let res = await get(`/patient/list/${page}/10`)
      console.log(res)
      setTotalPaginationCount(res?.totalPages * 10 || 0);
      setPatientData(res.resultList);
      
    } catch (error) {
      console.log(error)
      setPatientData([]);
    }
    
  }


  const handleChangePagination = (page) => {
    setPageNumber(page)
    getPatients(page);
  }




  useEffect(() => {
    getPatients()
  }, []);
 

  return (
    <div className="w-100 m-t-80">
      <h3>Diagnosis & Treatment</h3>
      
      <div className="flex w-100 space-between">
        
        <div className="flex flex-v-center  w-50 m-t-20">
        </div>
      </div>

      <div className="">
        <PatientsTable data={patientData} setPatientData={setPatientData} setTotalPaginationCount={setTotalPaginationCount}/>
        <div className="flex flex-h-end m-t-20">
          <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount} />
        </div>
      </div>
    </div>
  );
}

export default ManageRequest;

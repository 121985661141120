import avater from '../../assets/images copy/user-avater.png'


const DashboardCards = ({count, title, icon}) =>{
    return (
        <div className='m-r-10 cards'>
            <div className="p-l-10 p-r-10 p-t-20 p-b-20 white-bg flex">
                <div className='m-r-20'><img src={icon || avater} alt="icon" /></div>
                <div>
                    <div>{count}</div>
                    <div>{title}</div>
                </div>

            </div>
        </div>
    )
}

export default DashboardCards;
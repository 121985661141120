import React, { useState } from "react";
import { get } from "../../utility/fetchFinance";
import FinanceModal from "../layouts/FinanceModal";

function FinanceTable({ data }) {
  console.log(data);
  const [showFinanceModal, setShowFinanceModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const getTreatmentDetails = async (row) => {
    console.log(row);
    try {
      let res = await get(`/api/patientpayment/${row?.id}`)
      setSelectedRow(res);
      setShowFinanceModal(true);
      console.log(res);
    } catch (error) {
      console.log(error);
    }

  }


  return (
    <div className="w-100 ">
      <div className="w-100 none-flex-item m-t-20">
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              <th>First Name</th>
              <th>Last Name</th>
              <th>Diagnosis</th>
              <th>Cost</th>
              <th>HMO</th>
              <th>Balance</th>
              <th>Amount Due</th>
              <th>Date Created</th>
            </tr>
          </thead>

          <tbody className="white-bg view-det-pane">
            {data.map((row) => (
              <tr onClick={() => { getTreatmentDetails(row) }} className="hovers pointer" key={row.id}>
                <td>{row?.patient?.firstName}</td>
                <td>{row?.patient?.lastName}</td>
                <td>{row.diagnosis}</td>
                <td>{row.totalCost}</td>
                <td>{row.hasHMO ? "Yes" : "No"}</td>
                <td>{row.patientTotalBalance}</td>
                <td>{row.patientTotalDuePay}</td>
                <td>{new Date(row.createdOn).toDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showFinanceModal && <FinanceModal data={selectedRow} closeModal={() => setShowFinanceModal(false)} />}
    </div>
  );
}

export default FinanceTable;

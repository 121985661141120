import DiagnosisTable from "../tables/DiagnosisTable";

const Treatment = () => {
    return (
        <div className="w-100 m-t-80">
            <div className="flex">
                <h3>Diagnosis and Treatment</h3>
                {/* <h3 className="m-l-5">Sandra John</h3> */}
            </div>

            <DiagnosisTable />
        </div>
    )
}

export default Treatment;
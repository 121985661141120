
import { useEffect, useState } from "react";
import TagInputsCheck from "./TagInputCheck";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { get, post, put } from "../../utility/fetch";
import { get as getPatient } from "../../utility/fetchNurse";
import notification from "../../utility/notification";
import Notify from "../../utility/notify";
import axios from "axios";

const assignOptions = [
    {
        name: "Select Action",
        value: ""
    },
    {
        name: "Assign",
        value: "Occupied"
    },
    {
        name: "Unassign",
        value: "Vacant"
    },
    {
        name: "Unavailable",
        value: "Unavailable"
    },
]

const FinanceModal = ({ closeModal, data }) => {

    console.log(data)
   
    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '60%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 w-80">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >
                        <h3 className="m-b-10"></h3>
                        <table className="payment-breakdown-table">
                            <thead>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>Product</th>
                                    <th>Cost</th>
                                    <th>HMO Cover</th>
                                    <th>Due Pay</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.paymentBreakdowns?.map((breakdown) => (
                                    <tr key={breakdown.id}>
                                        {/* <td>{breakdown.id}</td> */}
                                        <td>{breakdown.serviceOrProductName}</td>
                                        <td>{breakdown.cost}</td>
                                        <td>{breakdown.hmoCover}</td>
                                        <td>{breakdown.duePay}</td>
                                        <td>{breakdown.status === "Not Paid" ?  <p className="text-red">Not Paid</p> : <p>{breakdown.status}</p>}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default FinanceModal;
/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Routes, Route } from 'react-router';
import Dashboard from '../components/pages/Dashboard';
import Patients from '../components/pages/Patients';
import Facility from '../components/pages/Facility';
import CustomerEngagement from '../components/pages/CustomerEngagement';
import PatientDetails from '../components/pages/PatientDetails';
import AuthRoute from './AuthRoute';
import PatientsFinance from '../components/pages/Finance';
import Settings from '../components/pages/Settings/Settings';
import PatientsInsurance from '../components/pages/Pinsurance';
import ReferredPatients from '../components/pages/Referrals';
import AddPatients from '../components/pages/AddPatients';
import UserManagement from '../components/pages/UserManagement/UserManagement';
import DiagnosisTable from '../components/tables/DiagnosisTable';
import ManageRequest from '../components/pages/ManageRequest';
import Treatment from '../components/pages/Treatment';
import InventoryTable from '../components/tables/InventoryTable';
import ManageInventory from '../components/pages/ManageInventory';

export default () => (
    <Routes>
        <Route path="/dashboard" element={<AuthRoute><Dashboard /></AuthRoute>} />
        {/* <Route path="/patients" element={<AuthRoute><Patients /> </AuthRoute>} /> */}
        <Route path="/files" element={<AuthRoute><Facility /></AuthRoute>} />
        {/* <Route path="/user-management" element={<AuthRoute><UserManagement /></AuthRoute>} /> */}
        {/* <Route path="/patient-details" element={<AuthRoute><PatientDetails /></AuthRoute>} />  */}
        {/* <Route path="/patients/add" element={<AuthRoute><AddPatients /></AuthRoute>} /> */}
        <Route path="/manage-request" element={<AuthRoute><ManageRequest /></AuthRoute>} />
        <Route path="/manage-inventory" element={<AuthRoute><ManageInventory /></AuthRoute>} />
        <Route path="/treatment" element={<AuthRoute><Treatment /></AuthRoute>} />
        {/* <Route path="/recent" element={<AuthRoute><PatientsInsurance /></AuthRoute>} /> */}
        <Route path="/finance" element={<AuthRoute><PatientsFinance /></AuthRoute>} />
        {/* <Route path="/customer-engagement" element={<AuthRoute><CustomerEngagement /></AuthRoute>} /> */}
        {/* <Route path="/shared-files" element={<AuthRoute><ReferredPatients /></AuthRoute>} /> */}
        <Route path="/deleted-files" element={<AuthRoute><Settings /></AuthRoute>} />
    </Routes>

);

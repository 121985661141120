import React, { useEffect, useState } from "react";
import TagInputs from "./TagInputs";
import { IoMdClose } from 'react-icons/io'
import { post, put } from "../../utility/fetch";
import { get } from "../../utility/fetchFinance";
import notification from "../../utility/notification";
import { get as getFinance } from "../../utility/fetchFinance";
import Notify from "../../utility/notify";
import { useSearchParams } from "react-router-dom";

const UpdateModal = ({ closeModal, modalData, getPatientDiagnosis, treatments, setRoomData, recipient, roomId, data, getRooms }) => {
    const [payload, setPayload] = useState({
        pharmacistNote: "",
        isDispensed: false
    });

    const [userNames, setUserNames] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState("");


    const [searchParams] = useSearchParams();

    console.log(data)
    console.log(data?.prescriptions[0]?.medId)
    console.log(modalData);


    useEffect(() => {
        getPatientFinance();
        getPaymentStatus(modalData?.medId);

    }, [])


    const getPaymentStatus = async (id) => {
        if (id === null) {
            setPaymentStatus("-");
        }
        else {
            let res = await getFinance(`/api/patientpayment/getpaymentstatusbymedid?id=${id}`)
            console.log(res)
            if(Array.isArray(res)){
                setPaymentStatus(res[0]?.status)
            }
            else{
                setPaymentStatus("-")
            }
            
        }

    }



    const handleChange = (event) => {
        if (event.target.name === "equipmentId") {
            setPayload({
                ...payload,
                equipmentId: Number(event.target.value),
            })
        }
        else if (event.target.name === "patientId") {
            setPayload({
                ...payload,
                patientId: Number(event.target.value),
            })
        }
        else if (event.target.name === "quantity") {
            setPayload({
                ...payload,
                quantity: Number(event.target.value),
            })
        }
        else {
            setPayload({ ...payload, [event.target.name]: event.target.value });
        }
    }

    const update = async () => {
        let res = await put(`/patient/dispense-prescription/${modalData?.id}`, payload);
        console.log(res);
        if (res?.statusCode === 500) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 400) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 401) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else if (res?.statusCode === 404) {
            Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
            return;
        }
        else {
            Notify({ title: "Success", message: "Prescription Updated Successfully", type: "success" });
            setPayload({
                pharmacistNote: "",
                isDispensed: false
            });
            getPatientDiagnosis(searchParams.get('id'));
            closeModal();
        }
    }




    // if (res?.statusCode === 409) {
    //     Notify({ title: "Error", message: "Equipment Already Assigned", type: "danger" });
    //     return;
    // }
    // else if (res?.statusCode === 400) {
    //     Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
    //     return;
    // }
    // else if (res?.statusCode === 401) {
    //     Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
    //     return;
    // }
    // else if (res?.statusCode === 404) {
    //     Notify({ title: "Error", message: res?.errorData[0], type: "danger" });
    //     return;
    // }
    // else {
    //     Notify({ title: "Success", message: "Equipment Assigned Successfully", type: "success" });
    //     setPayload({
    //         equipmentId: 0,
    //         roomId: roomId,
    //         location: "",
    //         patientId: 0,
    //         assignNote: "",
    //     });
    //     closeModal();

    // }
    // }


    const getPatientFinance = async () => {
        let res = await get(`/api/patientpayment/list/patient/${data?.patientId}/1/100/patient-payment-history`);
        console.log(res);
    }



    return (
        <div className="overlay m-t-20 ">
            <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
            <div style={{ width: '80%', margin: '0 auto' }} >
                <div className=" no-padding bg-white white-bg p-20 ">
                    <div
                        className="m-t-10 p-t-10 flex-h-center modal-content-large"
                        style={{
                            boxShadow: '0px 2px 6px #0000000A',
                            // backgroundColor: 'white'
                        }}
                    >

                        <h2>
                            Prescription
                        </h2>
                        <table className="bordered-table">
                            <thead className="border-top-none">
                                <tr className="border-top-none">
                                    <th>Date</th>
                                    <th>prescribing Doctor</th>
                                    <th>Diagnosis</th>
                                    <th>Medication/Prescription</th>
                                    <th>Qty</th>
                                    <th>Freq/Day</th>
                                    <th>Duration(Days)</th>
                                    <th>Total Dispense</th>
                                    <th>Payment Status</th>
                                    <th>Pharmacist's Note</th>
                                </tr>
                            </thead>

                            <tbody className="white-bg view-det-pane">
                                <React.Fragment key={data?.id}>
                                    <tr>
                                        <td rowSpan={data?.prescriptions?.length + 1}>
                                            {new Date(data?.dateOfVisit).toLocaleDateString()}
                                        </td>
                                        <td rowSpan={data?.prescriptions?.length + 1}>{`${treatments?.doctor?.firstName ||" N/A "} ${treatments?.doctor?.lastName || "N/A"}`}</td>
                                        <td rowSpan={data?.prescriptions?.length + 1}>{data?.diagnosis}</td>
                                        <td >{modalData?.pharmacyInventory?.productName || "NIL"}</td>
                                        <td>{modalData.quantity}</td>
                                        <td>{modalData.frequency}</td>
                                        <td>{modalData.duration}</td>
                                        <td>{modalData.totalToDispense}</td>
                                        <td>{paymentStatus && (paymentStatus === "Not Paid" ? <div className="text-red">{paymentStatus}</div> : paymentStatus)}</td>
                                        <td>{modalData.pharmacistNote}</td>
                                    </tr>


                                </React.Fragment>
                            </tbody>
                        </table>

                        <h3 className="m-b-10 m-t-20">Add Note</h3>
                        <TagInputs type="textArea" value={data && payload?.pharmacistNote} name="pharmacistNote" onChange={handleChange} label="Note" />

                        <div className="flex">
                            <input className="m-r-10" type="checkbox" id="isDispensed" name="isDispensed" onChange={() => setPayload({
                                ...payload,
                                isDispensed: !payload?.isDispensed
                            })} checked={payload?.isDispensed} />
                            <label htmlFor="isDispensed">is Dispensed?</label>
                        </div>


                        <button disabled={payload?.pharmacistNote?.length < 1 || paymentStatus === "Not Paid"} onClick={update} className="btn w-100 m-t-10">Submit</button>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default UpdateModal;
import React, { useEffect, useState } from "react";
import { stats } from "./mockdata/PatientData";
import StatCard from "../UI/StatCard";
import PatientsBreakdown from "../UI/PatientsBreakdown";
import PatientAdmission from "../UI/PatientAdmission";
import PatientsTable from '../tables/PatientsTable';
import GenderDistribution from "../UI/GenderDistribution";
import OutAndInpatientGraph from "../UI/OutAndInpatientGraph";
import { get } from "../../utility/fetch";
import { RiGroup2Fill, RiHotelBedFill } from "react-icons/ri";
import DashboardCards from "../layouts/DashboardCards";
import { numberWithCommas } from "../../utility/general";
import DiagnosisTable from "../tables/DiagnosisTable";
import InventoryTable from "../tables/InventoryTable";

function Dashboard() {

  const [pendingRequests, setPendingRequests] = useState(0)
  const [itemsBelowLimit, setItemsBelowLimit] = useState(0)
  const [inStock, setInStock] = useState(0)
  const [patientsWithHMO, setPatientsWithHMO] = useState([])
  const [genderStat, setGenderStat] = useState([])
  const [hourlyCount, setHourlyCount] = useState([])
  const [contributionCalculation, setContributionCalculation] = useState([])
 






  const getPendingRequests = async () => {
    let res = await get("/dashboard/pending-requests")
    console.log(res)
    setPendingRequests(res)

  }
  const getItemsBelowLimit= async () => {
    let res = await get("/dashboard/pharmacy/items-below-limit")
    console.log(res)
    setItemsBelowLimit(res)

  }

  const getInStock= async () => {
    let res = await get("/dashboard/stock/total-items-in-stock");
    console.log(res);
    setInStock(res)

  }

  const getContributionCalCulation = async () => {
    let res = await get("/dashboard/revenue/hmo/contribution-calculation");
    console.log(res);
    setContributionCalculation(res)

  }

  const getPatientsWithHMO = async () => {
    let res = await get("/dashboard/patients-with-hmo");
    console.log(res);
    setPatientsWithHMO(res)

  }

  const getGenderStat = async () => {
    let res = await get("/dashboard/patients-percentage-by-gender");
    console.log(res);
    setGenderStat(res)

  }

  const getHourlyCount = async () => {
    let res = await get("/dashboard/dashboard/patients/patients-admission-hourly-count");
    console.log(res);
    setHourlyCount(res)
  }
  
 
  useEffect(() => {
    getContributionCalCulation()
    getPatientsWithHMO()
    getGenderStat()
    getHourlyCount()
    getInStock()
    getItemsBelowLimit()
    getPendingRequests()
  }, []);

  return (
    <div className="w-100 m-t-80">
      <div className=" flex m-t-40">
        <DashboardCards title={"Pending Requests"} count={numberWithCommas(pendingRequests || 0)} icon={null} />
        <DashboardCards title={"Items Below Limit"} count={numberWithCommas(itemsBelowLimit || 0)} icon={null} />
        <DashboardCards title={"Total Item in Stock"} count={numberWithCommas(inStock || 0)} icon={null} />
        <DashboardCards title={"HMO Contribution"} count={numberWithCommas(isNaN(contributionCalculation) ? 0 : contributionCalculation)} icon={null} />
        <DashboardCards title={"Patients with HMO"} count={numberWithCommas(patientsWithHMO || 0)} icon={null} />

      </div>
      <div className="m-t-20">
        <div className="flex">
          {" "}
          <div className="m-r-20">
            <InventoryTable />
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default Dashboard;
